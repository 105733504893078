@value colors: "../../../../components/colors.module.css";
@value neutral-10, neutral-20, neutral-40, primary-80, primary-100, primary-120 from colors;

.chat_agent{
    background-color: neutral-10;
    padding: 0 15px 15px 15px;
    margin: auto;
    max-width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat_agent_wrapper {
    position: relative;
    overflow: scroll;
    padding: 15px;
}

.chat_agent_input_wrapper{
    margin-top: auto !important;
    border-radius: 10px;
}
.chat_agent_input{
    border-radius: 10px;
}
.chat_agent_input_btn_wrapper{
    border-radius: 10px;
    background-color: primary-100;
    pointer-events: none;

    &:hover, &:active, &:focus{
        background-color: primary-120;
    }
}
.chat_agent_input_btn{
    color: white;
    border: none;
    background: none;
    pointer-events: auto;

    &:hover, &:focus, &:active{
        background: none;
        color: neutral-20;
    }
}
.chat_agent_input_btn:disabled{
    background: none;
    color: neutral-40;

    &:active, &:hover, &:focus{
        background: none;
    }
}

.chat_agent_output_wrapper{
    padding: 15px;
}
.chat_agent_output_agent, .chat_agent_output_user{
    box-shadow: 0 0 9px #00000020;
    padding: 6px 15px;
    margin: 20px 0;
    max-width: 98%;
    width: fit-content;
}
.chat_agent_output_agent{
    border-radius: 10px 10px 10px 0;
    background-color: white;
}
.chat_agent_output_user{
    border-radius: 10px 10px 0 10px;
    margin-left: auto;
    background-color: primary-120;
    color: neutral-10;
    white-space: pre-wrap;
}

.dot_flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: primary-100;
    color: primary-100;
    animation: dot_flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin: 6px 15px;
}
.dot_flashing::before, .dot_flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot_flashing::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: primary-100;
    color: primary-100;
    animation: dot_flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot_flashing::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: primary-100;
    color: primary-100;
    animation: dot_flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot_flashing {
    0% {
        background-color: primary-100;
    }
    50%, 100% {
        opacity: .5;
    }
}