.ai-chat-init-container {
    position: fixed;
    bottom: 7px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 52px;
    cursor: pointer;
    z-index: 1;
}

.ai-chat-init-container > svg {
    box-shadow: 0px 3px 8px 1px rgba(0,0,0,0.25);
    border-radius: 50%;
}

.ai-chat-window-container {
    background-color: white;
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 420px;
    height: 85vh;
    z-index: 1;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 8px 1px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
}

.ai-chat-window-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: solid 1px #e5e5e5;
}

.ai-chat-window-header > svg {
    margin-right: 0.5rem;   
}

.ai-chat-window-header > span {
    font-weight: 600;
    color: #292929;
}