@value colors: "../../components/colors.module.css";
@value neutral-5, neutral-10, neutral-100,  primary-100 from colors;
.generic_flex_fit {
  display: flex;
  align-items: center;
  height: 100vh;
}

.bg_login_page {
  z-index: 1;
  height: 100vh;
  position: relative;
  background: neutral-5 !important;
}

.forgot_password {
  color: primary-100;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
  text-decoration: underline;
}

.forgot_password:hover {
  text-decoration: underline;
}

.bg_login_page:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  opacity: 0.5;
  z-index: -1;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('assets/images/login-bg-image-2.jpg');
}

.bg_login_page_skeleton {
  z-index: 1;
  height: 100vh;
  position: relative;
}

.login_card {
  padding: 18px 0px !important;
  text-align: center;
  /* word-spacing: .1rem;
  letter-spacing: .1rem; */
  border-left: 1px solid silver !important;
  border-right: 1px solid silver !important;
  border-bottom: unset !important;
  border-top: unset !important;
  border-radius: 0px !important;
  height: auto;
  transition: height 0.3s ease-out;
  overflow: hidden;
}

.login_card_skeleton {
  text-align: center;
  /* word-spacing: .1rem;
  letter-spacing: .1rem; */
  border-radius: 5px !important;
  border: 1px solid rgb(222, 222, 222) !important;
}

.login_hr {
  width: 100%;
  border: 1px solid neutral-10;
}

.login_sign_in_button {
  padding: 10px 16px;
  gap: 8px;
  background: primary-100 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 14px;
  border: none !important;
}

.login_sign_in_button:hover {
  opacity: 1 !important;
  background-color: #0b7ea2 !important;
}

button:disabled.login_sign_in_button {
  color: white !important;
}

.login_sign_in_with_google_button {
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  background: neutral-10 !important;
  border-radius: 4px;
  color: neutral-100;
  font-size: 14px;
  border: none !important;
}

.sign_in_with_google_text {
  display: block;
  text-align: center;
}

.login_sign_in {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  color: neutral-100;
}

.login_form_item {
  margin-bottom: 10px !important;
}

.login_google_logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.login_card_top {
  height: 12px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: white;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  border-top: 1px solid silver;
  transition: 0.3s;
}

.login_card_bottom {
  height: 12px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: white;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
  transition: 0.3s;
}

.login_bottom_links a {
  font-weight: 600;
  color: primary-100;
}
