.generic_flex_fit {
  display: flex;
  align-items: center;
  height: 100%;
}

.bg_password_page {
  z-index: 1;
  height: 100vh;
  position: relative;
}

.password_title {
  font-weight: 500;
  margin-bottom: 15px;
}

.password_description {
  margin-bottom: 15px;
}

.bg_password_page:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: .7;
  z-index: -1;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('assets/images/login-bg-image.jpg');
}

.password_card {
  text-align: center;
  border-radius: 5px !important;
  border: 1px solid silver !important;
}

.password_button {
  width: 90% !important;
  opacity: .7 !important;
  font-weight: 400 !important;
  background-color: #0B7EA2 !important;
}

.password_button:hover {
  opacity: 1 !important;
  background-color: #0B7EA2 !important;
}
