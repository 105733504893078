.ant-select-dropdown:has(.dropdown-two-lines-option) {
  max-width: 366px !important;
  min-width: 366px !important;
}

.dropdown-two-lines-option .ant-select-item-option-content,
.dropdown-two-lines-option{
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: normal;
  min-height: 54px !important;
  max-height: 54px !important;
}