@value colors: "../../components/colors.module.css";
@value neutral-5, neutral-10, neutral-100,  primary-100, primary-120 from colors;

.noodle_circle_logo {
  height: 30px;
  margin: 16px 0 16px 0px;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.sidebar_icon_disabled {
  filter: invert(85%) sepia(16%) saturate(12%) hue-rotate(315deg) brightness(96%) contrast(87%);
}
.site_layout_background {
  background: white !important;
}
.site_header {
  background: white !important;
  padding: 10px !important;
  max-height: 50px !important;
  line-height: 0px !important;
}
.site_header_col {
  display: flex !important;
  align-items: center !important;
}
.site_header .menu_icon,
.close_icon {
  color: primary-100 !important;
  font-size: 22px;
}

.site_header .report_button {
  background-color: primary-100;
  border: none;
}
.site_header .report_button:hover {
  background-color: primary-120 !important;
  border: none;
}
.site_header_cursor_pointer {
  cursor: pointer;
}
.main_content {
  padding: 16px 24px;
  background: neutral-5;
}

.mc_responsive {
  margin: 16px auto;
}
@media screen and (min-width: 1280px) {
  .mc_responsive {
    width: 85%;
  }
}
@media screen and (max-width: 1280px) {
  .mc_responsive {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .header_background {
    background: url('~assets/icons/noodle_complete_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .site_header .report_button {
    display: none;
  }
  .sidebar-logo,
  .ant-menu-item-selected {
    display: none !important;
    background-color: white !important;
  }
  .ant-layout-sider-zero-width-trigger {
    margin-top: 50px;
    height: 100vh;
    position: absolute !important;
    z-index: 999;
  }
}

.skeleton_header_background {
  text-align: center !important;
}

.ant-layout-sider-zero-width-trigger > span {
  display: none;
}
.sidebar-logo,
.ant-menu-item-selected {
  background-color: white !important;
  margin-top: 10px !important;
}
.sidebar-logo::after {
  opacity: 0 !important;
}

.skeleton_card {
  margin-top: 20px;
  padding: 0 10px 10px 10px;
  height: 390px !important;
  width: 100% !important;
}

.migration {
  margin-top: 10% !important;
  animation-duration: 1s;
}

.anim_fade_in {
  animation-name: fade-in;
  animation-timing-function: ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(14px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Side Bar */

.divider {
  border-bottom: 2px solid neutral-10;
  width: 30px;
  position: relative;
  left: -50%;
  bottom: 0;
  height: 1px;
  margin: 0;
}

.divider__container {
  height: 2px !important;
  margin: 0 !important;
}

.menu-item__collapsed {
  margin: 0px !important;
}

.divider__not-collapsed {
  border-bottom: 1px solid neutral-10;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 1px;
  margin: 0px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
}
.login_bottom_links {
  margin: 0;
}
.login_bottom_links a {
  font-weight: 600;
  color: primary-100;
}
