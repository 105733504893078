.header_background {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_icon_title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.teams_icon {
  background-color: white;
  padding: 18px;
  border-radius: 7px;
  box-shadow: -1px 2px 4px 3px rgb(0 0 0 / 4%);
}

.header_button {
  float: right; 
}

.back_to {
  color: #0b7ea2;
  font-weight: 500;
}

.header_title {
  display: inline-block;
  font-weight: 400;
  vertical-align: top;
  padding: 0 0 0 25px;
  font-size: 1.50rem;
  align-items: center;
  align-content: center;
}